'use strict';

var R6MLangTermsSpanish = (function(R6MLangTerms, undefined) {
  var name = 'es',
    terms = {
      general: {
        pageTitleSelectMap: 'R6 Siege Mapas - Seleccione un mapa',
        cameraViewCaption: '{floorName} Visión de cámara',
        shortcutTip: 'Atajo: {shortcut}',
        menu: 'Menú',
        about: 'Sobre',
        languageHeader: 'Lengua',
        optionsHeader: 'Opciones',
        labelLosOpacity: 'Opacidad de la linea de vision de la cámara',
        labelPercent: '{int}%',
        labelLosDefault: '(Default)',
        labelLos105: '(Huh?)',
        labelLos110: '(Absurdo!)',
        labelRoomLabelStyle: 'Estilo de nombre de sala',
        labelNumberFloorsToDisplay: 'Número de plantas a mostrar',
        lockPanning: 'Bloqueo de la panorámica',
        lockZooming: 'Fijar zoom',
        fullScreen: 'Pantalla completa',
        enableScreenshots: 'Habilitar captura de pantalla',
        contributions: 'Contibuciones',
        supportSiteNote: 'Muestre su apoyo con una pequeña donación.',
        donateImg: 'https://www.paypalobjects.com/es_ES/ES/i/btn/btn_donate_LG.gif'
      },
      roomLabelStyles: {
        Dark: 'Oscuro',
        Light: 'Claro(Predefinido)',
        DarkAndLarge: 'Grande y oscuro',
        LightAndLarge: 'Grande y claro',
        DarkAndSmall: 'Pequeño y oscuro',
        LightAndSmall: 'Pequeño y claro',
        DisplayNone: 'Sin nombres'
      },
      floorDisplayOptions: {
        one: '1 - Único',
        two: '2 - Doble',
        four: '4 - Múltiple'
      },
      selectMaps: {
        selectAMap: 'Seleccione un mapa',
        homeLink: 'Seleccione un mapa'
      },
      compass: {
        letterN: 'N',
        letterE: 'E',
        letterS: 'S',
        letterW: 'W'
      },
      floorNames: {
        basement: { full: 'Sótano', short: 'S' },
        firstFloor: { full: '1ª Planta', short: '1' },
        secondFloor: { full: '2ª Planta', short: '2' },
        thirdFloor: { full: '3ª Planta', short: '3' },
        fourthFloor: { full: '4ª Planta', short: '4' },
        roof: { full: 'Tejado', short: 'T' }
      },
      objectives: {
        bombShortA: 'A',
        bombShortB: 'B',
        bomb: 'Bomba',
        hostageShort: 'R',
        hostage: 'Rehén',
        secureShort: 'C',
        secure: 'Contenedor',
        showAll: 'Mostrar todo'
      },
      legend: {
        breakableWalls: 'Paredes destruibles',
        breakableFloorTraps: 'Trampillas del suelo',
        ceilingHatches: 'Trampillas del techo',
        lineOfSightWalls: 'Línea de las paredes',
        lineOfSightFloors: 'Línea de los suelos destruibles',
        droneTunnels: 'Droneras',
        objectives: 'Objetivos',
        insertionPoints: 'Puntos de inserción',
        securityCameras: 'Camaras de seguridad',
        skylights: 'Claraboya',
        onFloorAboveOrBelow: 'Planta por encima o por debajo',
        cameraLineOfSight: 'Linea de vision de la cámara',
        ladders: 'Escaleras'
      },
      spawnPoints: {
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E'
      },
      mapNames: {
        bank: 'Banco',
        bartlett: 'U. Bartlett',
        border: 'Frontera',
        chalet: 'Chalet',
        club: 'Club',
        coastline: 'Litoral',
        consulate: 'Consulado',
        favela: 'Favela',
        hereford: 'Base de Hereford',
        house: 'Casa',
        kanal: 'Canal',
        kafe: 'Café Dostoyevsky',
        oregon: 'Oregón',
        plane: 'Avión presidencial',
        skyscraper: 'Rascacielos',
        yacht: 'Yate'
      },
      mapRooms: {
        bank: {
          spawnBoulevard: 'Frontal del<br/>aparcamiento',
          spawnBackAlley: 'Acceso callejón',
          printerRoom: 'Sala de las<br/>impresoras',
          parkingLot: 'Parte frontal<br/>del aparcamiento',
          boulevard: 'Paseo',
          jewelryFront: 'Parte frontal<br/>de la joyería',
          plaza: 'Plaza',
          mainEntrance: 'Entrada principal',
          garageRamp: 'Rampa<br/>del garage',
          exteriorParking: 'Aparcamiento exterior',
          garageRoof: 'Tejado<br/>del garage',
          alleyAccess: 'Acceso<br/>al callejón',
          backAlleyRooftop: 'Tejado del<br/>callejón trasero',
          backAlley: 'Callejón trasero',
          highRoof: 'Tejado alto',
          lowRoof: 'Tejado bajo',
          vault: 'Cámara',
          goldVault: 'Reserva de oro',
          serverRoomStairs: 'Escalera sala<br/>del servidor',
          serverRoom: 'Sala del<br/>servidor',
          CCTVRoom: 'Sala CCTV<br/>de vigilancia',
          loadingDock: 'Zona de carga',
          secureHallway: 'Pasillo de<br/>seguridad',
          sewer: 'Alcantarillas',
          lockers: 'Taquillas',
          vaultLobby: 'Vestíbulo<br/>de la cámara',
          vaultEntrance: 'Entrada<br/>a la cámara',
          mainStairway: 'Escalera<br/>principal',
          bankGarage: 'Garage del banco',
          elevators: 'Ascensores',
          tellersOffice: 'Oficina de<br/>efectivo',
          archives: 'Archivo',
          tellers: 'Ventanillas de<br/>efectivo',
          loanOffice: 'Oficina de prestamos',
          officeHallway: 'Pasillo de los<br/>despachos',
          skylightStairwell: 'Escalera a la<br/>claraboya',
          lobby: 'Vestíbulo',
          openArea: 'Área abierta',
          staffRoom: 'Sala de personal',
          electricalRoom: 'Cuarto de<br/>electricidad',
          adminOffice: 'Oficina de<br/>administración',
          ATMs: 'Cajeros<br/>automáticos',
          executiveHallway: 'Pasillo ejecutivo',
          frontDesk: 'Mostrador',
          executiveLounge: 'Sala de espera<br/>ejecutiva',
          CEOOffice: 'Despacho del<br/>director',
          janitorCloset: 'Armario del<br/>conserje',
          hallway: 'Pasillo',
          terrace: 'Terraza',
          stockTradingRoom: 'Oficina de<br/>inversiones',
          conferenceRoom: 'Sala de<br/>conferencias'
        },
        bartlett: {
          archwayHall: 'Salón de<br/>los arcos',
          backAlley: 'Callejón trasero',
          bathroom: 'Baño',
          campusField: 'Jardines<br/>del campus',
          classroom: 'Aula',
          coatRoom: 'Ropero de<br/>abrigos',
          compassHallway: 'Pasillo de la<br/>brújula',
          courtyard: 'Patio',
          centralHallway: 'Pasillo central',
          diningRoom: 'Comedor',
          eastBalcony: 'Balcón este<br/>(no aparece)',
          eastCorridor: 'Pasillo este',
          eastStairs: 'Escalera este',
          festival: 'Festival',
          frontEntrance: 'Entrada delantera',
          frontOffice: 'Oficina frontal',
          frontPatio: 'Patio delantero',
          gardenPass: 'Paseo de los<br/>jardines',
          kitchen: 'Cocina',
          lobby: 'Vestíbulo',
          lounge: 'Sala de espera',
          lowerLibrary: 'Biblioteca<br/>inferior',
          mainGate: 'Puerta pricipal',
          mainOffice: 'Oficina<br/>principal',
          modelHall: 'Salón de<br/>los modelos',
          pantry: 'Despensa',
          parking: 'Aparcamiento',
          pianoRoom: 'Sala del piano',
          readingRoom: 'Sala de lectura',
          roof: 'Tejado',
          rowingMuseum: 'Museo del remo',
          serviceRoom: 'Cuartos<br/>de servicio',
          terrace: 'Terraza',
          trophyRoom: 'Sala de<br/>trofeos',
          upperLibrary: 'Biblioteca<br/>superior',
          vistaHallway: 'Pasillo del<br/>mirador',
          westBalcony: 'Balcón oeste<br/>(no aparece)',
          westCorridor: 'Pasillo oeste'
        },
        border: {
          armoryLockers: 'Taquillas<br/>de la armería',
          tellers: 'Puestos de cajeros',
          ventilationRoom: 'Sala de<br/>ventilación',
          exitHallway: 'Pasillo de<br/>salida',
          supplyCorridor: 'Pasillo de<br/>suministros',
          detention: 'Área de<br/>detención',
          customsInspection: 'Inspección<br/>de aduanas',
          customsDesk: 'Despacho<br/>de aduanas',
          centralStairs: 'Escalera<br/>central',
          serverRoom: 'Sala del<br/>servidor',
          supplyRoom: 'Sala de<br/>suministros',
          workshop: 'Taller',
          mainLobby: 'Vestíbulo<br/>principal',
          bathroom: 'Baño',
          waitingRoom: 'Sala de<br/>espera',
          eastStairs: 'Escalera<br/>este',
          passportCheck: 'Control de<br/>pasaportes',
          archives: 'Archivos',
          offices: 'Oficinas',
          officesHallway: 'Pasillo de<br/>la oficina',
          fountain: 'Fuente',
          mainHallway: 'Pasillo<br/>principal',
          armoryDesk: 'Armero',
          securityRoom: 'Sala de<br/>seguridad',
          breakRoom: 'Sala de<br/>descanso',
          spawnEastVehicleEntrance: 'Salida este<br/>de vehículos',
          spawnValley: 'Valle',
          spawnWestVehicleExit: 'Salida oeste<br/>de vehículos',
          westTower: 'Torre oeste',
          pedestrianExit: 'Salida de<br/>peatones',
          valley: 'Valle',
          parkingLotEntrance: 'Entrada al<br/>aparcamiento',
          parkingLot: 'Aparcamiento',
          westRoad: 'Carretera<br/>oeste',
          vehicleCustoms: 'Control aduana<br/>de vehículos',
          crashScene: 'Zona del<br/>accidente',
          eastRoad: 'Carretera<br/>este',
          pedestrianEntrance: 'Entrada<br/>peatonal',
          pedestrianCustoms: 'Control aduana<br/>de peatones',
          watchTower: 'Torre de<br/>vigilancia',
          eastAlley: 'Callejón<br/>este',
          parkingLotAlley: 'Callejón al<br/>aparcamiento',
          northBalcony: 'Balcón<br/>norte',
          eastBalcony: 'Balcón<br/>este',
          westBalcony: 'Balcón<br/>oeste',
          southBalcony: 'Balcón<br/>sur',
          roof: 'Tejado'
        },
        coastline: {
          aquarium: 'Acuario',
          backAlley: 'Callejón<br/>trasero',
          balcony: 'Balcón',
          bathroom: 'Baños',
          billiardsRoom: 'Sala de billar',
          blueBar: 'Bar azul',
          cantina: 'Bar abierto',
          courtyard: 'Patio',
          djBooth: 'Cabina del DJ (no aparce)',
          garageRoof: 'Tejado del<br/>garage',
          hallOfFame: 'Salón de<br/>famosos',
          hallway: 'Pasillo',
          hookahDeck: 'Terraza de<br/>cachimbas<br/>(no aparece)',
          hookahLounge: 'Sala de<br/>cachimbas',
          kitchen: 'Cocina',
          mainEntrance: 'Entrada<br/>principal',
          mainLobby: 'Vetíbulo principal',
          northStairs: 'Escalera<br/>norte',
          office: 'Oficina',
          penthouse: 'Ático',
          pool: 'Piscina',
          poolEntrance: 'Entrada a la piscina',
          poolSide: 'Lateral de la piscina',
          rooftop: 'Tejado',
          ruins: 'Ruinas',
          securityRoom: 'Sala de<br/>seguridad',
          serviceEntrance: 'Entrada de servicio',
          southHallway: 'Pasillo sur',
          southPromenade: 'Paseo sur',
          southStairs: 'Escalera<br/>sur',
          sunriseBar: 'Salón amanecer',
          sunRoom: 'Solario',
          theater: 'Sala de TV',
          terrace: 'Terraza',
          toilets: 'Baños',
          vipLounge: 'Sala VIP',
          walkway: 'Acera'
        },
        chalet: {
          spawnFrontYard: 'Patio<br/>delantero',
          spawnCampfire: 'Fuego<br/>campamento',
          spawnCliffside: 'Acantilado',
          spawnLakeside: 'Orilla del lago',
          libraryStairs: 'Escalera a<br/>la biblioteca',
          snowmobileGarageCorridor: 'Pasillo garage<br/>motos de nieve',
          snowmobileGarage: 'Garage de<br/>motos de nieve',
          greatRoomStairs: 'Escalera<br/>al salón',
          storageRoom: 'Sala de<br/>almacén',
          wineCellar: 'Bodega',
          wineStock: 'Cava de<br/>vinos',
          basementHallway: 'Pasillo del<br/>sotano',
          backyardStairs: 'Escalera al<br/>patio trasero',
          mainStairs: 'Escalera<br/>principal',
          mainGarage: 'Garage<br/>principal',
          garageEntrance: 'Entrada al<br/>garage principal',
          westEntrance: 'Entrada<br/>oeste',
          gamingRoomHallway: 'Pasillo de la<br/>sala de juegos',
          gamingRoom: 'Sala de<br/>juegos',
          bar: 'Bar',
          greatRoom: 'Salón',
          diningRoom: 'Comedor',
          mainEntrance: 'Entrada<br/>principal',
          trophyRoom: 'Sala de trofeos',
          kitchenHallway: 'Pasillo de<br/>la cocina',
          kitchen: 'Cocina',
          libraryHallway: 'Pasillo de la biblioteca',
          libraryEntrance: 'Entrada a la<br/>biblioteca',
          library: 'Biblioteca',
          bedroomTerrace: 'Terraza de las<br/>habitaciones',
          fireplaceHallway: 'Zona de la<br/>chimenea',
          bedroomHallway: 'Pasillo de las<br/>habitaciones',
          masterBathroom: 'Baño<br/>principal',
          masterBedroom: 'Habitacion principal',
          office: 'Oficina',
          woodenTrail: 'Camino del<br/>bosque',
          campfireWood: 'Claro del fuego<br/>de campamento',
          backyard: 'Patio trasero',
          gazeebo: 'Mirador',
          cliffsideStairs: 'Escalera del<br/>acantilado',
          cliffsideWoods: 'Camino del<br/>acantilado',
          backyardPatio: 'Plataforma del<br/>patio trasero',
          officeBalcony: 'Balcón de<br/>la oficina',
          helipadTrail: 'Pista al<br/>helipuerto',
          helipad: 'Helipuerto',
          frontYardPatio: 'Plataforma del<br/>patio delantero',
          frontYard: 'Patio delantero',
          bathroomBalcony: 'Balcón<br/>del baño',
          libraryBalcony: 'Balcón de<br/>la biblioteca',
          bedroomBalcony: 'Balcón de las<br/>habitaciones',
          snowmobiles: 'Motos de nieve'
        },
        club: {
          shippingDock: 'Muelle de carga',
          warehouse: 'Almacén',
          constructionSite: 'Zona de obras',
          easternSubroof: 'Subtejado este',
          container: 'Contenedor',
          graffitiArea: 'Pared de<br/>grafitis',
          recreationArea: 'Área de<br/>descanso',
          junkyard: 'Zona de<br/>chatarra',
          vipParking: 'Aparcamiento<br/>VIP',
          mainGate: 'Puerta<br/>principal',
          parking: 'Aparcamiento',
          kennels: 'Perreras',
          trash: 'Basura',
          centralSubroof: 'Subtejado<br/>central',
          easternRoof: 'Tejado este',
          centralRoof: 'Tejado central',
          westernRoof: 'Tejado oeste',
          balcony: 'Balcón',
          escapeTunnel: 'Túnel de<br/>escape',
          arsenalRoom: 'Sala del<br/>arsenal',
          basementHallway: 'Pasillo del sótano',
          memorialRoom: 'Sala museo',
          utilityRoom: 'Cuarto de<br/>limpieza',
          oilPit: 'Depósito de<br/>gasoil',
          centralStairs: 'Escalera<br/>central',
          church: 'Capilla',
          frontPorch: 'Porche frontal',
          garage: 'Garage',
          lobby: 'Vestíbulo',
          stockRoom: 'Sala de<br/>negocios',
          garageStorage: 'Amacén del<br/>garage',
          lounge: 'Sala de espera',
          bar: 'Bar',
          centralHallway: 'Pasillo central',
          kitchen: 'Cocina',
          kitchenEntrance: 'Entrada a la cocina',
          westernHallway: 'Pasillo oeste',
          stripClub: 'Club de<br/>striptease',
          junkyardEntrance: 'Entrada zona<br/>de chatarra',
          sideEntrance: 'Entrada lateral',
          changingRoom: 'Camerinos',
          bedroom: 'Habitación',
          bathroom: 'Baño',
          bedroomHallway: 'Pasillo a las<br/>habitaciones',
          logisticOffice: 'Oficina de<br/>logística',
          gym: 'Gimnasio',
          secretStash: 'Escondite<br/>secreto',
          cctvRoom: 'Sala de CCTV<br/>de vigilancia',
          cashRoom: 'Sala del<br/>dinero',
          easternStairs: 'Escalera<br/>este'
        },
        consulate: {
          spawnRiotBarricade: 'Barricada<br/>antitumultos',
          spawnPoliceLine: 'Cordón<br/>policial',
          spawnGasStation: 'Gasolinera',
          spawnSideEntrance: 'Entrada<br/>lateral',
          exitStairs: 'Escaleras<br/>de salida',
          garage: 'Garage',
          basementCorridor: 'Pasillo del<br/>sótano',
          securityRoom: 'Sala de<br/>seguridad',
          cafeteria: 'Cafetería',
          mainStairs: 'Escalera<br/>principal',
          lockerHallway: 'Pasillo de<br/>taquillas',
          serviceStairs: 'Escalera<br/>de servicio',
          electricRoom: 'Cuarto<br/>eléctrico',
          storageRoom: 'Zona de<br/>almacén',
          archives: 'Archivo',
          archivesCorridor: 'Pasillo del<br/>archivo',
          pressRoom: 'Sala de<br/>prensa',
          westCorridor: 'Pasillo oeste',
          publicBathroom: 'Baño público',
          antechamber: 'Antesala',
          lobby: 'Vestíbulo',
          eastCorridor: 'Pasillo este',
          tellers: 'Ventanillas de<br/>atención pública',
          visaOffice: 'Despacho<br/>de visados',
          visaEntrance: 'Entrada de<br/>visados',
          frontDoor: 'Puerta<br/>frontal',
          balcony: 'Balcón',
          copyRoom: 'Sala de<br/>fotocopiadoras',
          cabinet: 'Armario',
          administrationOffice: 'Oficina de<br/>administración',
          breakRoom: 'Sala de<br/>descanso',
          frontOffice: 'Oficina<br/>frontal',
          meetingRoom: 'Sala de<br/>reuniones',
          hallway: 'Pasillo',
          consulFrontDesk: 'Mostrador del<br/>consulado',
          privateBathroom: 'Baño privado',
          waitingRoom: 'Sala de<br/>espera',
          consulateOffice: 'Oficina del<br/>cónsul',
          garageWay: 'Acceso al<br/>garage',
          courtyard: 'Patio',
          backCourtyard: 'Patio trasero',
          sideEntrance: 'Entrada lateral',
          dumpster: 'Contendor<br/>de basura',
          parking: 'Aparcamiento',
          gardens: 'Jardines',
          fountain: 'Fuente',
          emergencyExit: 'Salida de<br/>emergencia',
          garageRoof: 'Tejado del<br/>garage',
          memorialGarden: 'Jarín de<br/>estatuas',
          policeLine: 'Cordón policial',
          riotBarracade: 'Barricada Antitumultos',
          eastFrontYard: 'Este del<br/>jardín delantero',
          westFrontYard: 'Oeste del<br/>jadín delantero',
          frontAlley: 'Callejón<br/>delantero',
          buildingRoof: 'Tejado del edificio'
        },
        favela: {
          packagingRoom: 'Sala de<br/>empaquetado',
          footballApartment: 'Apartamento<br/>de fútbol',
          armoryRoom: 'Sala de<br/>armas',
          auntsApartment: 'Apartamento<br/>de la tía',
          auntsBedroom: 'Dormitorio<br/>de la tía',
          growRoom: 'Zona de<br/>plantas',
          bikersApartment: 'Apartamento<br/>del motero',
          methLab: 'Laboratorio de<br/>metanfetamina',
          footballBedroom: 'Dormitorio<br/>de fútbol',
          footballOffice: 'Despacho<br/>de fútbol',
          bikersBedroom: 'Dormitorio<br/>del motero',
          backStairs: 'Escaleras<br/>traseras',
          auntsHall: 'Vestíbulo<br/>de la tía',
          kidsRoom: 'Cuarto de<br/>los niños',
          mainStairs: 'Escaleras<br/>principales',
          stairHall: 'Vestíbulo de<br/>las escaleras',
          roof: 'Tejado',
          laundryRoom: 'Cuarto de<br/>lavado',
          vaultRoom: 'Sala de<br/>la cámara',
          bikersGarage: 'Garage del<br/>motero',
          backAlley: 'Callejón trasero',
          schoolAlley: 'Callejón de la escuela',
          footballPitch: 'Campo de<br/>fútbol',
          market: 'Mercado',
          marketAlley: 'Callejón <br/>e mercado',
          schoolRooftops: 'Tejado de<br/>la escuela',
          street: 'Calle',
          rooftops: 'Tejados',
          courtyard: 'Patio',
          accessAlley: 'Callejón<br/>de acceso',
          shop: 'Tienda<br/>(no se muestra)',
          marketRooftops: 'Tejados del<br/>mercado'
        },
        hereford: {
          shootingRange: 'Galería de tiro',
          mainStairs: 'Escalera<br/>principal',
          garage: 'Garage',
          kitchen: 'Cocina',
          diningRoom: 'Comedor',
          masterBedroom: 'Habitación<br/>principal',
          laundryRoom: 'Lavandería',
          bathroom: 'Baño',
          workshop: 'Taller',
          rooftop: 'Tejado'
        },
        house: {
          spawnConstructionSite: 'Zona de obras',
          spawnRiverDocks: 'Embarcaderos<br/>del río',
          spawnAPCArea: 'Área de<br/>los VTB',
          spawnSideStreet: 'Calle lateral',
          depot: 'Almacén',
          trainingRoom: 'Gimnasio',
          kitchenStairs: 'Escaleras de<br/>la cocina',
          sideStairs: 'Escalera<br/>lateral',
          laundryRoom: 'Cuarto de<br/>lavado',
          garage: 'Garage',
          livingRoom: 'Sala de<br/>estar',
          backEntrance: 'Entrada posterior',
          lobby: 'Vestíbulo<br/>de entrada',
          kitchen: 'Cocina',
          office: 'Oficina',
          diningRoom: 'Comedor',
          workshop: 'Taller',
          kidsBedroom: 'Cuarto<br/>de los niños',
          upperHallway: 'Pasillo<br/>superior',
          lobbyStairs: 'Escalera<br/>de entrada',
          walkIn: 'Armario<br/>vestidor',
          masterBedroom: 'Dormitorio<br/>principal',
          bathroom: 'Baño',
          sideStreet: 'Calle lateral',
          garageEntrance: 'Entrada del<br/>garage',
          garden: 'Jardín',
          backAlley: 'Callejón trasero',
          patio: 'Patio',
          jacuzzi: 'Jacuzzi',
          basementStairs: 'Escaleras<br/>del sotano',
          treehouseAlley: 'Callejón de la<br/>casa del árbol',
          frontYard: 'Patio delantero',
          frontStreet: 'Calle delantera',
          frontPorch: 'Porche delantero',
          backPorch: 'Porche trasero',
          backPorchTop: 'Tejado del<br/>porche trasero',
          frontPorchTop: 'Tejado del<br/>porche delantero',
          rooftop: 'Tejado'
        },
        kanal: {
          floatingDock: 'Embarcadero',
          sailboats: 'Veleros',
          constructionSite: 'Zona de obras',
          pipes: 'Tuberias que<br/>rezuman',
          lockerRoom: 'Taquillas',
          archives: 'Archivo',
          lounge: 'Sala de<br/>espera',
          modelRoom: 'Sala de<br/>modelos',
          securityRoom: 'Sala de<br/>seguridad',
          projectorRoom: 'Sala del<br/>proyector',
          kitchen: 'Cocina',
          controlRoom: 'Sala de<br/>control',
          controlRoomHallway: 'Pasillo<br/>a la sala<br/>de control',
          serverRoom: 'Sala del<br/>servidor',
          lockGate: 'Compuerta',
          quayContainers: 'Contenedores<br/>del muelledw',
          lockGateTunnel: 'Tunel de las<br/>compuertas',
          constructionEntrance: 'Entrada a la<br/>zona de obras',
          parkingAlley: 'Callejón del<br/>aparcamiento',
          parkingEntrance: 'Entrada al<br/>aparcamiento',
          middleRoad: 'Carretera<br/>central',
          forkliftAlley: 'Callejón<br/>carretilla<br/>elevadora',
          frontLawn: 'Césped<br/>delantero',
          coastGuardRoof: 'Tejado de<br/>guardacostas',
          balcony: 'Balcón',
          parking: 'Aparcamiento'
        },
        kafe: {
          riverDocks: 'Embarcadero del río',
          christmasMarket: 'Mercadillo navideño',
          park: 'Callejón del parque',
          laundryRoom: 'Sala de<br/>lavandería',
          bakery: 'Obrador de<br/>pastelería',
          diningRoom: 'Comedor',
          museumEntrance: 'Entrada<br/>al museo',
          miningRoom: 'Sala de minería',
          trainMuseum: 'Museo del tren',
          mainCorridor: 'Pasillo<br/>principal',
          readingRoomCorridor: 'Pasillo a la<br/>sala de lectura',
          readingRoom: 'Sala de<br/>lectura',
          fireplaceHall: 'Salón de<br/>la chimenea',
          cigarShop: 'Tienda de<br/>cigarros',
          cigarLounge: 'Sala para<br/>fumadores',
          bar: 'Bar',
          barBackstore: 'Parte trasera<br/>del bar',
          washrooms: 'Lavavos',
          cocktailLounge: 'Sala de<br/>cócteles',
          cocktailLoungeEntrance: 'Entrada<br/>a la sala<br/>de cócteles',
          westMainStreet: 'Calle principal<br/>zona oeste',
          mainStreet: 'Calle principal<br/>zona oeste',
          eastMainStreet: 'Calle principal<br/>zona este',
          bakeryParking: 'Aparcamiento<br/>del obrador',
          bakeryRoof: 'Tejado del obrador',
          cafeRoofTop: 'Parte alta del<br/>tejado del café',
          terrace: 'Terraza',
          backAlley: 'Callejón trasero',
          garage: 'Garage'
        },
        oregon: {
          junkyard: 'Desguace',
          street: 'Calle',
          constructionSite: 'Zona de obras',
          towerStairs: 'Escaleras<br/>de la torre',
          boilerRoom: 'Sala de<br/>calderas',
          electricRoom: 'Cuarto de<br/>electricidad',
          bunker: 'Búnker',
          basementCorridor: 'Pasillo del<br/>sótano',
          supplyRoom: 'Sala de<br/>suministros',
          laundryRoom: 'Lavandería',
          laundryStorage: 'Almacén de<br/>la lavandería',
          laundryStairs: 'Escaleras de<br/>la lavandería',
          diningHall: 'Salón<br/>comedor',
          showers: 'Duchas',
          kitchen: 'Cocina',
          classroom: 'Aula',
          lobby: 'Vestíbulo',
          mainStairs: 'Escalera<br/>principal',
          meetingHall: 'Salón de<br/>reuniones',
          rearStage: 'Escenario<br/>posterior',
          garage: 'Garage',
          dormMainHall: 'Entrada principal<br/>a los dormitorios',
          armoryCorridor: 'Pasillo a la armería',
          masterBedroom: 'Dormitorio principal',
          armory: 'Armería',
          walkIn: 'Armario<br/>vestidor',
          attic: 'Ático',
          busYard: 'Depósito de autubuses',
          farmlands: 'Granjas',
          shootingRange: 'Galería<br/>de tiro',
          parking: 'Aparcamiento',
          mainEntrance: 'Entrada principal',
          balcony: 'Balcón',
          diningHallRoof: 'Tejado del<br/>salón comedor',
          garageRoof: 'Tejado del<br/>garage',
          dormsRoof: 'Tejado de<br/>los dormitorios',
          meetingHallRoof: 'Tejado del salón<br/>de reuniones',
          supplyCloset: 'Armario de<br/>suministros'
        },
        plane: {
          spawnOfficialEntrance: 'Entrada principal',
          spawnReporterEntrance: 'Entrada de prensa',
          spawnServiceEntrance: 'Entrada frontal de servicio',
          pressBathroom: 'Ba.Pr',
          meetingRoom: 'Sala de<br/>reuniones',
          frontHallway: 'Pasillo<br/>frontal',
          executiveOffice: 'Oficina<br/>ejecutiva',
          mainEntrance: 'Entrada principal',
          frontStairs: 'Escalera delantera',
          pantry: 'Despensa',
          kitchen: 'Cocina',
          executiveHallway: 'Pasillo ejecutivo',
          executiveBedroom: 'Habitación<br/>ejecutiva',
          changeRoom: 'Vestuario',
          laund: 'Lavand.',
          frontServiceEntrance: 'Entrada frontal<br/>de servicio',
          rightWing: 'Ala derecha',
          backServiceEntrance: 'Entrada trasera<br/>de servicio',
          reporterEntrance: 'Entrada de prensa',
          leftWing: 'Ala izquierda',
          staffSection: 'Sección de<br/>personal',
          securityRoom: 'Sala de<br/>seguridad',
          pressSectionA: 'Zona de<br/>prensa A',
          pressSectionB: 'Zona de<br/>prensa B',
          backStairs: 'Escalera trasera',
          cargoHold: 'Bodega<br/>de carga',
          serviceCorridor: 'Pasillo de<br/>servicio',
          storage: 'Almacén',
          luggageHold: 'Bodega de<br/>equipaje',
          firstAidStation: 'Sala de<br/>atención médica',
          cargoFrontEntrance: 'Bodega<br/>entrada frontal',
          cockpitStairs: 'Escalera a<br/>la cabina',
          cabinStaff: 'Personal<br/>de cabina',
          radioCabin: 'Radio<br/>de cabina',
          cabin: 'Cabina',
          caterer: 'Zona de<br/>catering',
          serverRoomA: 'Sala del servidor A',
          serverRoomB: 'Sala del servidor B',
          technicalSeating: 'Zona de técnicos',
          ladderRoom: 'Zona de<br/>la escalera'
        },
        skyscraper: {
          helipad: 'Helipuerto',
          tower: 'Torre',
          ventilationUnits: 'Unidades de refrigeración',
          kitchen: 'Cocina',
          pantry: 'Despensa',
          deliveryRoom: 'Entrega de<br/>mercacía',
          houseLobby: 'Vestíbulo<br/>de la casa',
          houseEntrance: 'Entrada<br/>de la casa',
          mainEntrance: 'Entrada<br/>principal',
          reception: 'Recepción',
          bedroom: 'Habitación',
          closet: 'Armario',
          bathroom: 'Cuarto<br/>de baño',
          houseStairs: 'Escaleras<br/>de la casa',
          restaurant: 'Restaurante',
          toilet: 'Aseo',
          bbq: 'Barbacoa',
          backHallway: 'Callejón trasero',
          mainStairs: 'Escaleras<br/>principales',
          geishaRoom: 'Sala de<br/>geishas',
          hallway: 'Pasillo',
          karaoke: 'Karaoke',
          teaRoom: 'Salón<br/>del té',
          taiko: 'Taiko',
          terrace: 'Terraza',
          backStairs: 'Escaleras<br/>traseras',
          houseBalcony: 'Balcón de<br/>la casa',
          exhibition: 'Exposición',
          lounge: 'Sala de<br/>espera',
          workOffice: 'Despacho<br/>de trabajo',
          clearance: 'Entrada VIP',
          peacefullTree: 'Árbol de<br/>la paz',
          contemplationGarden: 'Jardín de<br/>meditación',
          westGarden: 'Jardín oeste',
          bridge: 'Puente',
          gazeebo: 'Mirador',
          restBalcony: 'Balcón<br/>rest.',
          northGarden: 'Jardín norte',
          eastGarden: 'Jardín este',
          sandGarden: 'Jardín<br/>de arena',
          sidePath: 'Camino<br/>lateral',
          sideStairs: 'Escaleras<br/>laterales',
          dragonStatue: 'Estatua de<br/>dragón',
          coveredWalkway: 'Pasaje cubierto'
        },
        themepark: {
          arcadeEntrance: 'Salón<br/>recreativo',
          arcadeStairs: 'Escaleras de<br/>salón recreativo',
          arcadeToilet: 'Aseos del<br/>salón<br/>recreativo',
          barrelRoom: 'Zona de<br/>barriles',
          backAlley: 'Callejón trasero',
          bumperCars: 'Coches<br/>de choque',
          bunk: 'Camastros',
          cafe: 'Café',
          cafeCorridor: 'Pasillo<br/>del café',
          cafeTerrace: 'Terraza<br/>del café',
          cashStash: 'Zona del<br/>dinero',
          controlRoom: 'Sala de<br/>control',
          dayCare: 'Guardería',
          guestInfo: 'Información<br/>para visitantes',
          initiationRoom: 'Sala de<br/>iniciación',
          jointCorridor: 'Pasillo general',
          lockerRoom: 'Área de<br/>taquillas',
          mainEntrance: 'Entrada principal',
          office: 'Oficina',
          palms: 'Palmeras',
          roboCircuit: 'Circuito de robots',
          sweetShop: 'Tienda de<br/>golosinas',
          teacups: 'Atracción de las tazas',
          upperArcade: 'Zona superior<br/>de los recreativos',
          village: 'Pueblo'
        },
        yacht: {
          spawnSubmarine: 'Subacuática',
          spawnZodiak: 'Lancha rápida hinchable',
          spawnSnowMobile: 'Moto de nieve',
          mapsRoom: 'Sala de mapas',
          cockpit: 'Puente de mando',
          cockpitHallway: 'Pasillo a<br/>puente de mando',
          captainsOffice: 'Cabina del<br/>capitán',
          cockpitBalcony: 'Balcón de cabina',
          topDeckStairs: 'Escaleras<br/>cubierta<br/>superior',
          helipadEntrance: 'Entrada del<br/>helipuerto',
          helipad: 'Helipuerto',
          spaDeck: 'Cubierta<br/>del spa',
          eastDeck: 'Cubierta<br/>de estribor',
          westDeck: 'Cubierta<br/>de babor',
          frontDeck: 'Cubierta de proa',
          masterBedroom: 'Camarote<br/>principal',
          casino: 'Casino',
          pokerRoom: 'Sala de<br/>póker',
          bathroom: 'Baño',
          bedroomHallway: 'Pasillo a<br/>camarotes',
          casinoHallway: 'Pasillo al casino',
          globeHallway: 'Sala del<br/>globo terráqueo',
          lounge: 'Sala de espera',
          cafeteria: 'Cafetería',
          engine: 'Sala de<br/>máquinas',
          backEntrance: 'Entrada trasera<br/>sala de máquinas',
          rearDeck: 'Cubierta<br/>de popa',
          serverRoom: 'Sala del<br/>servidor',
          engineStorage: 'Almacén de la<br/>sala de máquinas',
          engineControl: 'control de<br/>máquinas',
          backStairs: 'Escaleras<br/>de popa',
          emergencyExit: 'Salida de<br/>emergencia',
          engineHallway: 'Pasillo a la<br/>sala de máquinas',
          frontStairs: 'Escaleras<br/>de proa',
          kitchen: 'Cocina',
          staffDormitory: 'Camarotes<br/>tripulación',
          westBalcony: 'Candelero<br/>de babor',
          eastBalcony: 'Candelero<br/>de estribor',
          kitchenHallway: 'Pasillo a la cocina',
          kitchenStairs: 'Escaleras<br/>de la cocina',
          kitchenPantry: 'Despensa de<br/>la cocina',
          infirmary: 'Enfermería',
          borealSubRoom: 'Sala vehículo<br/>submarino aklark',
          cafeteriaHallway: 'Pasillo a cafetería',
          engineUtility: 'Zona<br/>auxiliar',
          submarine: 'Submarino',
          westGlacier: 'Glaciar oeste',
          eastHullBreach: 'Brecha en el<br/>casco a estribor',
          eastGlacier: 'Glaciar este',
          frozenRiver: 'Río congelado',
          zodiac: 'Lancha rápida hinchable',
          westHullBreach: 'Brecha en el<br/>casco de babor',
          kingOfTheWorld: 'El rey<br/>del mundo',
          roof: 'Techo',
          anchorName: 'Ancla',
          aklarkSubEntrance: 'Entrada<br/>Aklark'
        }
      }
    };

  R6MLangTerms.registerLanguage(name, terms);

  return  {
    name: name,
    terms: terms
  };
})(R6MLangTerms);
